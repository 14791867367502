<!--
 * @Author: Code-HHX
 * @Date: 2022-04-26 14:01:55
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-26 16:21:11
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-form @submit="onSubmit">
            <!-- <span class="vo-front-style">Pop-up ad Info</span> -->
            <!-- 广告名 -->
            <FormRow>
              <b-form-group
                label="Pop-up ad Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="advertise-name"
                label-class="requiredRow"
              >
                <b-form-input
                  v-model="form.advertiseName"
                  required
                  maxlength="100"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Plase enter"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.advertiseName ? form.advertiseName.length : 0"
                  max="100"
                />
              </template>
            </FormRow>
            <FormRow>
              <b-form-group
                label="Effective Time:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="production-time"
                label-class="requiredRow"
              >
                <date-picker
                  v-model="selectTime"
                  format="YYYY-MM-DD"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Plase enter"
                ></date-picker>
              </b-form-group>
            </FormRow>
            <FormRow>
              <b-form-group
                label="Learn More URL:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="url"
                label-class="requiredRow"
              >
                <b-form-input
                  v-model="form.url"
                  required
                  maxlength="250"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Plase enter"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText :len="form.url ? form.url.length : 0" max="250" />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="code-type"
                label-class="requiredRow"
              >
                <b-form-radio-group
                  id="code-type-1"
                  v-model="form.status"
                  :options="types.statusList"
                  name="code-type"
                  style="padding-top: 7px"
                >
                </b-form-radio-group>
              </b-form-group>
            </FormRow>
            <FormRow>
              <!-- 广告图片 -->
              <b-form-group
                label="Uploading ad Picture:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="pop-logo"
                label-class="requiredRow"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="filestyle-1"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="upload($event)"
                  :required="!form.coverImage"
                  oninvalid="setCustomValidity('Please upload');"
                  accept="image/*"
                  oninput="setCustomValidity('');"
                />

                <div class="bootstrap-filestyle input-group">
                  <span class="group-span-filestyle" tabindex="0">
                    <div v-if="form.coverImage">
                      <div class="upload-img-wrap">
                        <img :src="form.coverImage" class="upload-img" />
                        <img
                          @click="onRemoveLogo"
                          :src="require('@/assets/images/brand/ic_close.png')"
                          class="close-img"
                        />
                      </div>
                    </div>
                    <b-button
                      variant="primary"
                      class="custom-width"
                      @click="uploadFile()"
                      >upload picture</b-button
                    >
                    <span>
                      Recommended size of picture: 120 pixels * 120 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </FormRow>

            <FormRow style="margin-top: 100px">
              <b-form-group label-cols-lg="3">
                <b-button type="submit" variant="primary" class="custom-width"
                  >Save Pop-up-ad</b-button
                >
              </b-form-group>
            </FormRow>
          </b-form>
        </b-col>

        <b-col class="popImage" style="position: relative">
          <img
            :src="require('@/assets/pop-up.png')"
            class="vo-object-fit"
            style="
              height: 488px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          />
          <img
            :src="
              form.coverImage
                ? form.coverImage
                : require('@/assets/pop-up-ad.svg')
            "
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 161px;
            "
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row class="align-items-center">
        <b-col cols="12">
          <div class="blockquote">
            <h4>History Pop-up ad</h4>
          </div>
        </b-col>
      </b-row>
      <div class="table-responsive mb-0">
        <b-table
          show-empty
          empty-text="No records"
          id="table"
          head-variant="light"
          ref="table"
          :busy="isBusy"
          :striped="true"
          :items="provider"
          :fields="fields"
          :per-page="search_condition.pageSize"
          :current-page="search_condition.pageNum"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-includedfields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(index)="data">
            {{
              data.index +
              (search_condition.pageNum - 1) * search_condition.pageSize +
              1
            }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button-group>
              <b-button
                size="sm"
                variant="primary"
                @click="modifyPop(row.item)"
                class="mr-1"
              >
                Modify
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:cell(status)="row">
              <p v-if="row.item.status == 0" class="clientStyle">Disabled</p>
              <p v-if="row.item.status == 1">Enable</p>
            </template>
          <template v-slot:cell(startTime)="row">
              <p >{{row.item.startTime}} To {{row.item.endTime}}</p>
            </template>
        </b-table>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row v-if="has_data">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import Dialog from "@/components/Dialog";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import { dropzoneOptions, upload_img } from "@/api/upload";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    ReferenceBy,
    Dialog,
    FormRow,
    CountText,
    dropzoneOptions,
    upload_img,
  },
  data() {
    return {
      page: {
        title: "Pop-up Ad Mgmt",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "APP Settings",
            // href: "/"
          },
          {
            text: "Pop-up Ad Mgmt",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "id",
          label: "Ad ID",
          class: "text-center",
        },
        {
          key: "advertiseName",
          label: "Pop-up ad Name",
          class: "text-center",
        },
        {
          key: "startTime",
          label: "Effective Time",
          class: "text-center",
        },
        {
          key: "url",
          label: "Learn More URL",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Enable" : "Disable";
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        {
          key: "createName",
          label: "Createor",
          class: "text-center",
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      form: {
        id: "",
        advertiseName: "",
        startTime: "",
        endTime: "",
        url: "",
        status: 1,
        coverImage: "",
      },
      selectTime: [],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
      },
      types: {
        statusList: [
          { text: "Enable", value: 1, disabled: false },
          { text: "Disable", value: 0, disabled: false },
        ],
      },
      isBusy: false,
      has_data: false,
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  watch: {
    selectTime: function (val) {
      this.form.startTime = timeZoneConversion.formatTime(val[0], 1);
      this.form.endTime = timeZoneConversion.formatTime(val[1], 1);
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    onRemoveLogo() {
      this.form.coverImage = "";
    },
    init() {},
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.brandAdvertise.saveBrandAdvertise(this.form).then((res) => {
        if (res.success) {
          this.successAlear = true;
          this.$refs.table.refresh();
        } else {
          if (res.message) {
            this.errorAlear = true;
            this.errorContent = res.message;
          }
        }
      });
     
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.brandAdvertise
        .listPage(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    modifyPop(item) {
      this.form = {
        id: item.id,
        advertiseName: item.advertiseName,
        startTime: item.startTime,
        endTime: item.endTime,
        url: item.url,
        status: item.status,
        coverImage: item.coverImage,
      };
      this.selectTime = ['2024-01-01', '2024-01-07'];
      this.$nextTick();
      //this.selectTime = [item.startTime, item.endTime];
    },
    cancel() {
      this.successAlear = false;
    },
    hide() {
      this.successAlear = false;
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.success) {
            this.form.coverImage = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadFile() {
      const filestyle = document.getElementById("filestyle-1");
      filestyle.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-img {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: #02a7f0;
  border-radius: 50%;
  object-fit: contain;
}

.upload-img-wrap {
  margin-bottom: 1em;
  width: 200px;
  height: 200px;
  position: relative;
  border: 2px dashed #02a7f0;
}

.popImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 488px;
}
</style>
