var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-form",
        {
          attrs: { action: "#" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.typeForm($event)
            },
            reset: _vm.reset_form
          }
        },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.brandName
                                        ? _vm.typeform.brandName.length
                                        : 0
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Brand Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "brand-name",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.typeform.brandName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "brandName", $$v)
                                  },
                                  expression: "typeform.brandName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              ref: "businessType",
                              attrs: {
                                label: "Business Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-business",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(_vm.companyBusiness, function(
                                  label,
                                  index
                                ) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      staticClass: "mr-2 mt-2",
                                      attrs: {
                                        id: "businessType",
                                        required: true,
                                        value: label.value
                                      },
                                      model: {
                                        value: _vm.typeform.companyBusinessId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.typeform,
                                            "companyBusinessId",
                                            $$v
                                          )
                                        },
                                        expression: "typeform.companyBusinessId"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(label.text) + " ")]
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.brandIns
                                        ? _vm.typeform.brandIns.length
                                        : 0,
                                      max: 200
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Social Media Url:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-ins",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "brand-ins",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  maxlength: "200",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.typeform.brandIns,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "brandIns", $$v)
                                  },
                                  expression: "typeform.brandIns"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.brandUrl
                                        ? _vm.typeform.brandUrl.length
                                        : 0,
                                      max: 200
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Brand Official Website:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-url",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "brand-url",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  maxlength: "200",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.typeform.brandUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "brandUrl", $$v)
                                  },
                                  expression: "typeform.brandUrl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.brandIntroduction
                                        ? _vm.typeform.brandIntroduction.length
                                        : 0,
                                      max: 2000
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Brand Introduction:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-introduction",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "b-form-textarea",
                                {
                                  attrs: {
                                    id: "brand-introduction",
                                    placeholder: "Please enter",
                                    maxlength: "2000",
                                    rows: "3",
                                    required: "",
                                    "max-rows": "6"
                                  },
                                  model: {
                                    value: _vm.typeform.brandIntroduction,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.typeform,
                                        "brandIntroduction",
                                        $$v
                                      )
                                    },
                                    expression: "typeform.brandIntroduction"
                                  }
                                },
                                [_vm._v(" > ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Brand Logo:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-logo",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "filestyle",
                                staticStyle: {
                                  position: "absolute",
                                  clip: "rect(0px, 0px, 0px, 0px)"
                                },
                                attrs: {
                                  type: "file",
                                  "data-input": "false",
                                  id: "filestyle-1",
                                  tabindex: "-1",
                                  required: !_vm.typeform.file,
                                  oninvalid:
                                    "setCustomValidity('Please upload');",
                                  accept: "image/*",
                                  oninput: "setCustomValidity('');"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.upload($event)
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "bootstrap-filestyle input-group"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "group-span-filestyle",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _vm.typeform.file
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upload-img-wrap"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "upload-img",
                                                  attrs: {
                                                    src: _vm.typeform.file
                                                  }
                                                }),
                                                _c("img", {
                                                  staticClass: "close-img",
                                                  attrs: {
                                                    src: require("@/assets/images/brand/ic_close.png")
                                                  },
                                                  on: {
                                                    click: _vm.onRemoveBrandLogo
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "custom-width",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.uploadFile()
                                            }
                                          }
                                        },
                                        [_vm._v("upload picture")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Homepage Display Logo",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "homepage-logo",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("input", {
                                    staticClass: "filestyle",
                                    staticStyle: {
                                      position: "absolute",
                                      clip: "rect(0px, 0px, 0px, 0px)"
                                    },
                                    attrs: {
                                      type: "file",
                                      "data-input": "false",
                                      id: "homepage-logo",
                                      tabindex: "-1",
                                      required: !_vm.typeform.homePicture,
                                      oninvalid:
                                        "setCustomValidity('Please upload');",
                                      oninput: "setCustomValidity('');"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.uploadHomePicture($event)
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bootstrap-filestyle input-group"
                                    },
                                    [
                                      _c("div", {
                                        staticStyle: {
                                          position: "absolute",
                                          width: "100%",
                                          height: "35px",
                                          "z-index": "-1"
                                        },
                                        attrs: { name: "filedrag" }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "group-span-filestyle",
                                          attrs: { tabindex: "0" }
                                        },
                                        [
                                          _vm.typeform.homePicture
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px"
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "100px",
                                                      height: "100px",
                                                      border: "2px dashed #ccc"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.typeform.homePicture
                                                    }
                                                  }),
                                                  _vm.homePictureName
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "10px",
                                                            "vertical-align":
                                                              "bottom"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "500"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Picture name : "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.homePictureName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "btn btn-secondary",
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              },
                                              attrs: { for: "homepage-logo" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "buttonText" },
                                                [_vm._v(" upload picture ")]
                                              )
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "FormRow",
                            [
                              _vm.isUnicoreus
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Brand Details Picture",
                                        "label-cols-lg": "3",
                                        "label-align-lg": "right",
                                        "label-for": "brand-details",
                                        "label-class": "requiredRow"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "filestyle",
                                        staticStyle: {
                                          position: "absolute",
                                          clip: "rect(0px, 0px, 0px, 0px)"
                                        },
                                        attrs: {
                                          type: "file",
                                          "data-input": "false",
                                          id: "brand-details",
                                          tabindex: "-1",
                                          required: !_vm.typeform
                                            .brandDetailsPicture,
                                          oninvalid:
                                            "setCustomValidity('Please upload');",
                                          oninput: "setCustomValidity('');"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.uploadBrandDetailsPicture(
                                              $event
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bootstrap-filestyle input-group"
                                        },
                                        [
                                          _c("div", {
                                            staticStyle: {
                                              position: "absolute",
                                              width: "100%",
                                              height: "35px",
                                              "z-index": "-1"
                                            },
                                            attrs: { name: "filedrag" }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "group-span-filestyle",
                                              attrs: { tabindex: "0" }
                                            },
                                            [
                                              _vm.typeform.brandDetailsPicture
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "10px"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "100px",
                                                          height: "100px",
                                                          border:
                                                            "2px dashed #626ed4"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.typeform
                                                              .brandDetailsPicture
                                                        }
                                                      }),
                                                      _vm.brandDetailsPictureName
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                                "vertical-align":
                                                                  "bottom"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "500"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Picture name : "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.brandDetailsPictureName
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary",
                                                  staticStyle: {
                                                    "margin-bottom": "0"
                                                  },
                                                  attrs: {
                                                    for: "brand-details"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "buttonText"
                                                    },
                                                    [_vm._v(" upload picture ")]
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  " Recommended size of picture: 360 pixels * 88 pixels, supporting JPG and PNG formats. "
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "FormRow",
                            [
                              _vm.isIkrusher
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Subject:",
                                        "label-cols-lg": "3",
                                        "label-align-lg": "right",
                                        "label-for": "subject",
                                        "label-class": "requiredRow"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-wrap" },
                                        _vm._l(_vm.subjectList, function(
                                          label,
                                          index
                                        ) {
                                          return _c(
                                            "b-form-checkbox",
                                            {
                                              key: index,
                                              staticClass: "mr-2 mt-2",
                                              attrs: { value: label.value },
                                              model: {
                                                value: _vm.typeform.subjectId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.typeform,
                                                    "subjectId",
                                                    $$v
                                                  )
                                                },
                                                expression: "typeform.subjectId"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(label.text) + " "
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Brand Notes",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "brand-remark"
                                  }
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "remark",
                                      placeholder: "Enter Notes",
                                      rows: "3",
                                      "max-rows": "6"
                                    },
                                    model: {
                                      value: _vm.typeform.remarks,
                                      callback: function($$v) {
                                        _vm.$set(_vm.typeform, "remarks", $$v)
                                      },
                                      expression: "typeform.remarks"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-remark"
                              }
                            },
                            [
                              _c("b-form-select", {
                                staticClass: "col-md-3",
                                attrs: {
                                  options: _vm.statusOptions,
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.typeform.enableStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "enableStatus", $$v)
                                  },
                                  expression: "typeform.enableStatus"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }