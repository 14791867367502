/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-26 14:44:24
 * @Description:
 */
// http://47.115.181.84:8080/electronic-cigarette/doc.html

/**
 * api 接口的统一出口
 */

// 账号模块 接口
import account from './account.js'

// 用户管理模块
import userManage from './user.js'
import UserUseage from './UserUseage'
import UserAuthorization from './UserAuthorization'

// app 模块
import appManage from './appManage.js'

import Cartridge from './Cartridge'
import CartridgeBatch from './CartridgeBatch'
import CartridgeModel from './CartridgeModel'
import CartridgeUsage from './CartridgeUsage'
import Device from './Device'
import Message from './Message'
import Flavor from './flavor'
import Category from './Category'
import Dropdown from './Dropdown'
import Statistics from './statistics'
import excel from './excel'
import Marketing from './marketing.js'
import Label from './Label.js'

import systemManage from './systemManage'
import systemMaintenance from './systemMaintenance'
import warmUpMode from './warmUpMode'
import emptyCartridges from './emptyCartridges'
import Client from './Client'
import Role from './Role.js'

// 首页
import home from './home'

// 医疗管理
import medicalManage from './medicalManage'
//邀请码管理
import invitationCode from './invitationCode.js'

//弹出页管理
import popup from './popup.js'
//主题管理
import subject from './subject.js'

import assistant from './assistant.js'
//规格
import specifications from './specifications.js'
//广告
import brandAdvertise from './brandAdvertise.js'

const api = {
	account,
	userManage,
	UserUseage,
	UserAuthorization,

	appManage,
	CartridgeBatch,
	CartridgeModel,
	Cartridge,
	Device,
	Marketing,
	Label,
	CartridgeUsage,
	Message,
	Flavor,
	Category,
	Dropdown,
	Statistics,
	Client,
	Role,

	medicalManage,
	excel,
	systemManage,
	systemMaintenance,
	warmUpMode,
	emptyCartridges,
	home,
	invitationCode,
	popup,
	subject,
	assistant,
	specifications,
	brandAdvertise,
}

// 导出接口
export default api
